<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { useAttrs } from "vue";

type Props = {};
const {} = defineProps<Props>();
const classes: AntlerClasses<Props> = {
    base: "flex flex-col gap-4 lg:gap-8",
};

const attrs = useAttrs();

const { aClass } = installAntlerComponent("form", {}, classes, attrs);
</script>

<template>
    <form :class="aClass()">
        <slot />
    </form>
</template>
