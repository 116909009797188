<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { useAttrs } from "vue";

export type FormGroupVariant = "spaced";

type Props = {
    variant?: FormGroupVariant;
};

const { variant = undefined } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "flex flex-col gap-4",
    variants: {
        variant: {
            spaced: "",
        },
    },
};
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "form-group",
    { variant },
    classes,
    attrs,
);
</script>

<template>
    <div :class="aClass()">
        <slot />
    </div>
</template>
